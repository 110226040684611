import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { FC } from "react"

import { renderErrorOrLoading } from "../commons/renderErrorOrLoading"
import { useData } from "../data/useData"
import { useResults } from "../data/useResults"
import { useUser } from "../data/useUser"
import { Flag } from "./Flag"

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
})

export const Standings: FC = () => {
  const classes = useStyles()
  const { results, error, loading } = useResults()

  const { pricepool, countries } = useData()
  const { user } = useUser()
  const wide = window.innerWidth > 1000
  return (
    renderErrorOrLoading({ loading, error }) || (
      <TableContainer
        component={Paper}
        style={{ maxHeight: "420px", overflow: "auto" }}
      >
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {wide && <TableCell>position</TableCell>}
              <TableCell>name</TableCell>
              <TableCell>points</TableCell>
              {wide && <TableCell>prize</TableCell>}
              <TableCell>teams</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((row, index) => (
              <TableRow key={index}>
                {wide && <TableCell>{index + 1}</TableCell>}
                <TableCell
                  style={user === row.name ? { fontWeight: "bold" } : undefined}
                  component="th"
                  scope="row"
                >
                  {row.name}
                </TableCell>

                <TableCell> {row.score}</TableCell>
                {wide && (
                  <TableCell>
                    {pricepool && pricepool[index]?.amount
                      ? pricepool[index].amount + " eur"
                      : index === results.length - 1
                      ? " Incest and Shit with Grannies"
                      : ""}
                  </TableCell>
                )}
                <TableCell>
                  {countries &&
                    countries
                      .filter(c => c.assignedTo === row.name)
                      .map(c => (
                        <Flag
                          key={c.code}
                          country={c.code}
                          style={{
                            position: "relative",
                            width: wide ? 64 : 32,
                            height: wide ? 32 : 16,
                            display: "inline-block",
                            margin: 3,
                            opacity: c.eliminated ? 0.1 : 1,
                          }}
                        />
                      ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  )
}
