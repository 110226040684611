import { useEffect, useState } from "react"

import { fetchData } from "./fetchData"
import { Country, Matchup, PricepoolEntry, Row } from "./types"

export function useData() {
  const [data, setData] =
    useState<{
      matchups: Matchup[]
      countries: Country[]
      rows: Row[]
      pricepool: PricepoolEntry[]
    }>()
  const [error, setError] = useState<any>()

  useEffect(() => {
    fetchData().then(setData).catch(setError)
  }, [])
  const { matchups, countries, rows, pricepool } = data || {}
  return {
    matchups,
    countries,
    rows,
    pricepool,
    error,
    loading: !data && !error,
  }
}
