import { CircularProgress } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import React from "react"

interface Props {
  error?: any
  loading: boolean
}

export function renderErrorOrLoading({ error, loading }: Props) {
  if (error) {
    return <Alert severity="error">Something went wrong</Alert>
  }
  if (loading) {
    return <CircularProgress />
  }
  return null
}
