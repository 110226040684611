import { Container, Grid, Modal } from "@material-ui/core"
import { LoginIndicator } from "components/LoginIndicator"
import React, { FC, useState } from "react"

import { renderErrorOrLoading } from "../commons/renderErrorOrLoading"
import { MatchupView } from "../components/MatchupView"
import { Standings } from "../components/Standings"
import { Matchup } from "../data/types"
import { useData } from "../data/useData"
import { useUser } from "../data/useUser"
import { ResultsDialog } from "./ResultsDialog"
import { UserDialog } from "./UserDialog"

export const Main: FC = () => {
  const { matchups, loading, error } = useData()

  const [matchup, setMatchup] = useState<Matchup>()
  const { user, setUser } = useUser()
  const errorOrLoading = renderErrorOrLoading({ loading, error })
  if (errorOrLoading) return errorOrLoading
  return (
    <Container>
      <LoginIndicator />
      <Grid container spacing={4} justify={"space-evenly"}>
        <Grid item key={"standings"} xs={12}>
          <Standings />
        </Grid>
        {matchups &&
          matchups.map((v, i) => (
            <Grid item key={i}>
              <MatchupView {...v} onOpen={() => setMatchup(v)} />
            </Grid>
          ))}
      </Grid>
      <Modal
        open={!!matchup}
        onClose={() => setMatchup(undefined)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ResultsDialog {...{ matchup }} onClose={() => setMatchup(undefined)} />
      </Modal>
      <Modal open={!user} onClose={() => setUser("GUEST")}>
        <UserDialog onSetUser={setUser} />
      </Modal>
    </Container>
  )
}
