import { Chip } from "@material-ui/core"
import { Cancel, LooksOne, LooksTwo } from "@material-ui/icons"
import React, { FC } from "react"

import { Result } from "../data/types"

interface Props {
  correct: boolean
  mine: boolean
  odds?: number
  sign: Result
  margin?: number
}

export const OddsChip: FC<Props> = ({
  margin = 5,
  sign,
  correct,
  odds,
  mine,
}) => {
  return (
    <Chip
      label={odds ? odds : "TBA"}
      icon={renderIcon(sign)}
      color={correct ? "secondary" : mine ? "primary" : undefined}
      style={{
        margin: margin,
        ...((correct && mine && { backgroundColor: "#357a38" }) || undefined),
      }}
    />
  )
}
function renderIcon(sign: Result) {
  switch (sign) {
    case "1":
      return <LooksOne />
    case "X":
      return <Cancel />
    case "2":
      return <LooksTwo />
  }
}
