import React, { useState } from "react"

import { Main } from "./views/Main"
import { Signup } from "./views/Signup"

export function App() {
  const [showMain, setPreview] = useState<boolean>(shouldShowMain())

  if (showMain) return <Main />
  else return <Signup onSneakPreview={() => setPreview(true)} />
}
function shouldShowMain() {
  const start = Date.parse("2022-11-20 15:00:00.000 GMT")
  return Date.now() > start
}
