import { Matchup, Result } from "./types"
import { useData } from "./useData"
import { useRow } from "./useRow"

export function useResults(index: number = 0) {
  const { rows, loading, error } = useRow()
  const { matchups } = useData()

  if (!matchups) return { results: [], loading, error }
  if (index < 0) {
    index = matchups.length - 1
  }
  return {
    results: (rows || [])
      .map(({ name, row }) => {
        const ret = {
          name,
          score: calculateScore(row, matchups),
          curScore: getScore(row[index], matchups[index]),
          curPotentialScore: getScore(row[index], matchups[index], true),

          curOdds: getOdds(row[index], matchups[index]),
          curRow: row[index],
          curResult: matchups[index].result,
          correct: matchups[index].result === row[index],
          multiplier: matchups[index]?.multiplier || 1,
        }
        return ret
      })
      .sort((a, b) => b.score - a.score),
    loading,
    error,
  }
}

function calculateScore(row: Result[] = [], matchups: Matchup[] = []) {
  let sum = 0
  //@Kludge
  if (typeof row === "string") row = (row as string).split("") as Result[]
  row.forEach((result, index) => {
    sum += getScore(result, matchups[index])
  })
  return round(sum)
}

function getScore(
  result: Result,
  matchup?: Matchup,
  potential: boolean = false
) {
  const score = matchup
    ? round(getOdds(result, matchup) * (matchup.multiplier || 1))
    : 0
  if (!matchup || !result) return 0
  if (matchup.result !== result && !potential) return 0
  return score
}
function round(n: number) {
  return Math.round(1000 * n) / 1000
}
function getOdds(result: Result, matchup: Matchup) {
  switch (result) {
    case "1":
      return matchup.oddsHome!
    case "X":
      return matchup.oddsDraw!
    case "2":
      return matchup.oddsAway!
    default:
      return 0
  }
}
