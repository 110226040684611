import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { FC } from "react"

import { renderErrorOrLoading } from "../commons/renderErrorOrLoading"
import { OddsChip } from "../components/OddsChip"
import { Matchup } from "../data/types"
import { useResults } from "../data/useResults"
import { useUser } from "../data/useUser"

interface Props {
  matchup?: Matchup
  onClose: () => void
}
export const ResultsDialog: FC<Props> = ({ matchup, onClose }) => {
  const { results, loading, error } = useResults(matchup!.index)
  const { user } = useUser()

  return (
    renderErrorOrLoading({ loading, error }) || (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh", cursor: "pointer" }}
        onClick={onClose}
      >
        <TableContainer
          component={Paper}
          style={{ maxWidth: 420, maxHeight: "420px", overflow: "auto" }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>name</TableCell>

                <TableCell>result</TableCell>
                <TableCell>total points</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map(row => (
                <TableRow key={row.name}>
                  <TableCell
                    style={
                      user === row.name ? { fontWeight: "bold" } : undefined
                    }
                    component="th"
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell>
                    <OddsChip
                      margin={0}
                      correct={row.correct}
                      mine={user === row.name}
                      sign={row.curRow}
                      odds={row.curPotentialScore}
                    />
                  </TableCell>
                  <TableCell> {row.score}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    )
  )
}
