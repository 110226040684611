import { Button } from "@material-ui/core"
import React, { FC } from "react"

interface Props {
  onSneakPreview: () => void
}
export const Signup: FC<Props> = ({ onSneakPreview }) => {
  return (
    <>
      <div className="App">
        <h1>Fifa 2022</h1>
        <ul>
          <li>
            Vakio: jokaiselle arvotaan 1x2 rivit jokaiseen matsiin. Pisteita
            tulee ottelun vedonlyontikertointen mukaan &amp; jatkopeleista
            lisakerroin. Ei mitaan taitoelementtia ja jannitettavaa joka peliin.
          </li>
          <li>Voitonjako 50:30:20.</li>
          <li>50e/arpa, 1 arpa per nena.</li>
          <li>
            {" "}
            Haviajalle lohdutuspalkintona Incest and Shit with Grannies DVD
            elokuva lainaan seuraaviin kisoihin asti.
          </li>
          <li>
            Voittajaveto: arvotaan jokaiselle 1 osallistujamaa karkimaiden
            joukosta & loput ripulimaat arvotaan osallistujille.
          </li>
          <li>20e/arpa 1 arpa / nassu</li>
          <li>
            Ilmoittautuminen tapahtuu maksamalla 20e, 50e tai 70e tilille FI65
            3939 0033 5711 42 / JP Heimonen. Viestiksi mielellaan jotain
            huumeisiin, huoriin tai laittomiin uhkapeleihin liittyvaa
          </li>
          <li>
            Arvonta tuntia ennen kick-offia Otapokerin irc-galleriasivulla
          </li>
          <li>
            Tulosseurantasivu ilmestyy tanne
            <Button onClick={onSneakPreview}>sneak preview</Button>
          </li>
        </ul>
      </div>
    </>
  )
}
