import React, { FC } from "react"

import { useUser } from "../data/useUser"

export const LoginIndicator: FC = () => {
  const { user, clearUser } = useUser()
  if (!user) return null
  return (
    <div style={{ textAlign: "right", marginBottom: 10 }}>
      {user}
      <a
        onClick={() => {
          clearUser()
          window.location.reload()
        }}
        href="#"
        style={{ marginLeft: 5 }}
      >
        switch
      </a>
    </div>
  )
}
