import { useData } from "./useData"
import { useUser } from "./useUser"

export function useRow() {
  const { rows, error, loading } = useData()
  const { user } = useUser()
  return {
    rows,
    error,
    loading,
    myRow: rows?.filter(r => r.name === user)[0]?.row,
  }
}
