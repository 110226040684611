import { Country } from "./types"
import { useData } from "./useData"

export function useCountry(code: string) {
  const { countries, loading, error } = useData()
  const country = findCountry(countries, code)
  return { ...country, loading, error }
}

function findCountry(countries: Country[] = [], code: string) {
  return countries.filter(c => c.code === code)[0]
}
