import { CardMedia } from "@material-ui/core"
import React, { FC } from "react"

import { useCountry } from "../data/useCountry"

interface Props {
  country: string
  style: any
}

export const Flag: FC<Props> = ({ country, style }) => {
  const { flag, name, assignedTo } = useCountry(country)
  if (!flag) return null
  return (
    <CardMedia
      style={{ position: "absolute", height: 100, width: "50%", ...style }}
      image={flag}
      title={name + ": " + (assignedTo || "")}
    />
  )
}
