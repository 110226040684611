import React, { FC } from "react"

import { Result } from "../data/types"
import { OddsChip } from "./OddsChip"

interface Props {
  oddsHome?: number
  oddsAway?: number
  oddsDraw?: number
  result?: Result
  myRow?: Result
}

export const Odds: FC<Props> = ({
  myRow = "2",
  result,
  oddsHome,
  oddsAway,
  oddsDraw,
}) => {
  if (oddsHome && oddsDraw) {
    return (
      <>
        <OddsChip
          sign={"1"}
          odds={oddsHome}
          mine={myRow === "1"}
          correct={result === "1"}
        />
        <OddsChip
          sign={"X"}
          odds={oddsDraw}
          mine={myRow === "X"}
          correct={result === "X"}
        />
        <OddsChip
          sign={"2"}
          odds={oddsAway}
          mine={myRow === "2"}
          correct={result === "2"}
        />
      </>
    )
  } else if (oddsHome && !oddsDraw) {
    return (
      <>
        <OddsChip
          sign={"1"}
          odds={oddsHome}
          mine={myRow === "1"}
          correct={result === "1"}
        />
        <OddsChip
          sign={"2"}
          odds={oddsAway}
          mine={myRow === "2"}
          correct={result === "2"}
        />
      </>
    )
  } else if (myRow) {
    return <OddsChip sign={myRow} mine={true} correct={false} />
  }

  return <></>
}
