import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import React, { FC } from "react"

import { renderErrorOrLoading } from "../commons/renderErrorOrLoading"
import { Matchup } from "../data/types"
import { useRow } from "../data/useRow"
import { MatchupImage } from "./MatchupImage"
import { Odds } from "./Odds"

const useStyles = makeStyles({
  root: {
    maxWidth: Math.min(380, window.innerWidth - 40),
    minWidth: Math.min(380, window.innerWidth - 40),
  },
  media: {
    height: 100,
    position: "relative",
  },
  mediaInner: {
    height: 100,
    position: "absolute",
    top: 0,
    bottom: 0,
  },
})

export const MatchupView: FC<Matchup & { onOpen: () => void }> = ({
  title,
  description,
  home,
  away,
  stage,
  score,
  onOpen,
  image,
  multiplier,
  oddsHome,
  oddsAway,
  oddsDraw,
  result,
  date,
  index,
}) => {
  const classes = useStyles()
  const { myRow, error, loading } = useRow()

  return (
    renderErrorOrLoading({ loading, error }) || (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2" color="textSecondary" component="p">
            {date.toDateString()}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {stage}
          </Typography>
        </div>

        <Card className={classes.root} onClick={() => onOpen()}>
          <CardActionArea>
            <MatchupImage {...{ home, away, title, image }} />
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography gutterBottom variant="h5" component="h2">
                  {title ? title : `${home} - ${away}`}
                </Typography>

                {score && (
                  <Typography
                    style={{ fontWeight: "bolder" }}
                    gutterBottom
                    variant="h5"
                    component="h5"
                  >
                    {score}
                  </Typography>
                )}
              </div>
              <Typography variant="body2" color="textSecondary" component="p">
                {description}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions disableSpacing>
            <Odds
              oddsHome={oddsHome}
              oddsAway={oddsAway}
              oddsDraw={oddsDraw}
              result={result}
              myRow={myRow ? myRow[index] : undefined}
            />

            {multiplier && (
              <Box style={{ marginLeft: "auto" }}>x {multiplier}</Box>
            )}
          </CardActions>
        </Card>
      </>
    )
  )
}
