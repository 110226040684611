import { Chip, Grid, Paper, Typography } from "@material-ui/core"
import React, { FC } from "react"

import { useRow } from "../data/useRow"

interface Props {
  onSetUser: (user: string) => void
}

export const UserDialog: FC<Props> = ({ onSetUser }) => {
  const { rows } = useRow()

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={3}
        style={{ padding: 10, minHeight: 200, minWidth: 200 }}
      >
        <Typography style={{ fontWeight: "bold" }} component={"h1"}>
          Select user
        </Typography>

        <div
          style={{
            padding: 20,
            maxWidth: 420,
            maxHeight: 500,
            overflow: "auto",
          }}
        >
          {[...(rows || []), { name: "GUEST" }]
            .map(r => r.name)
            .map(name => (
              <Chip
                style={{ margin: 10 }}
                key={name}
                label={name}
                onClick={() => onSetUser(name)}
              />
            ))}
        </div>
      </Paper>
    </Grid>
  )
}
