import { CardMedia } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import React, { FC } from "react"

import { Flag } from "./Flag"

interface Props {
  image?: string
  home: string
  away: string
  title?: string
}

const useStyles = makeStyles({
  mediaInner: {
    height: 100,
    position: "absolute",
    top: 0,
    bottom: 0,
  },
})
export const MatchupImage: FC<Props> = ({ image, title, home, away }) => {
  return image ? (
    <CardMedia image={image} style={{ height: 200 }} title={title} />
  ) : (
    <div style={{ position: "relative", height: 100 }}>
      <Flag style={{ left: 0 }} country={home} />
      <Flag style={{ right: 0 }} country={away} />
    </div>
  )
}
